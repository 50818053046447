
.heading {
    background: rgba(230,250,230,.7);
    border-radius: 8px;
    padding: 30px;
    color: black;
    text-shadow: 2px 2px #f9f9f9
}
.contact {
    align-items: center;
    padding: 10px;
    color: black;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    text-shadow: 2px 2px #f9f9f9;
}
#sign-door{
    margin-top: 10px;
}

#all-btn:hover {
    filter: brightness(150%);
  }

.message {
    font-size: 30px;
}