@import url('https://fonts.googleapis.com/css2?family=Rampart+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');

h1, .exp, .name {
    font-family: 'Rampart One', cursive;
}
p, h2, h3, h4, h5, .message, .contactme, .navb, .show {
    font-family: 'Roboto Mono', monospace;
}
.name {
    font-size: 35px;
}
.btn {
    &.dark{
      background-color: lavender;
      border: none;
      color: #fff;
      &:hover{
        background-color: darken(lavender, 5%);
      }
    }
  }
.travel{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.art {
    display: flex;
    align-items: center;
}

#nature {
    width: 70px;
}

.icons {
    color: lavender;
}
.nav-collapsed {
    background-color: lavender;
}
.show {
  color: black;
}
.nav-link:hover {
    background-color: darken(lavender, 5%);
}      
#projects-rev, #projects-rig{
    margin-top: 30px;

    align-items: center;
    border: 1px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
    padding: 10px;
    background: rgba(230,250,230,.7);
    border-radius: 8px;
    padding: 30px;
    color: black;
    text-shadow: 2px 2px #f9f9f9
}
#projects-rev {
    display: flex;
    flex-direction: row-reverse;
}
.btn-flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.navb {
    background: lavender;
}
#footer-col{
    background-color: rgba(0, 0, 0, 0.7);
}
.hands{
    border-radius: 20px;
}
.left-side {
    margin-bottom: 30px;
    align-items: center;
    border: 1px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
    padding: 10px;
    color: black;
}

.App{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url("breakwall.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
}

.home-page {
    flex-grow: 1;
    
}
.con-icons{
    width: 50px;
}
.about-me{
    background: rgba(230,250,230,.7);
    border-radius: 8px;
    padding: 30px;
    color: black;
    text-shadow: 2px 2px #f9f9f9;
    margin-bottom: 10px;
}

p {
    font-size: 20px;
}

.all-proj {
margin-top: 10px;
}
.game-pro, .skills {
    margin-bottom: 30px;
}
.list-container {
    background: rgba(230,250,230,.7);
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 20px;;
}