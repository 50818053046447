@media screen and (max-width: 640px) {
  .skillsGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    grid-auto-rows: auto;
    height: auto;
    margin-bottom: 10px;
  }
.skillsGrid img {
  height: 75px;
  width: 75px;
}
.skills{
  background: rgba(230,250,230,.8);
  border-radius: 8px;
  padding: 30px;
  color: black;
  text-shadow: 2px 2px #f9f9f9;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

}


@media screen and (min-width: 641px) {
  .skillsGrid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    grid-auto-rows: auto;
    height: auto;
  }
.skillsGrid img {
  height: 100px;
  width: 100px;
}
.skills{
  background: rgba(230,250,230,.8);
  border-radius: 8px;
  padding: 30px;
  color: black;
  text-shadow: 2px 2px #f9f9f9;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}
}


@media screen and (min-width: 1080px) {
  .skillsGrid{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 50px;
    grid-auto-rows: auto;
    height: auto
  }
.skillsGrid img {
  height: 150px;
  width: 150px;
}
.skills{
  background: rgba(230,250,230,.8);
  border-radius: 8px;
  padding: 30px;
  color: black;
  text-shadow: 2px 2px #f9f9f9;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}
}
